export const contactData = [
    {
        name: "Twitter",
        icon: "./twitter.png",
        link: "https://twitter.com/oamh09",
    },
    {
        name: "Github",
        icon: "./github.png",
        link: "https://github.com/OsirisMH",
    },
    {
        name: "Mail",
        icon: "./mail.png",
        link: "mailto:oamh09@gmail.com",
    }
];