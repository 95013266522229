import React from 'react'

export const Footer = () => {
    return (
        <footer className="footer" >
            <span className="footer--copyright" >
                Copyright &#169; 2021 Osiris Meza - Todos los derechos reservados
            </span>
        </footer>
    )
}
